import React from "react";
import { Helmet } from "react-helmet";

const ApplepayPage = () => (
  <>
    <Helmet>
      <link href="/applepay.css" type="text/css" rel="stylesheet" />
    </Helmet>
    <div className="flex align-center column">
      <div className="container-root container-maxWidthLg">
        <div
          className="flex align-center justify-center"
          style={{padding: '21px 0', marginBottom: '24px'}}
        >
          <img
            src="https://i.ibb.co/zmpF55n/logo-Big-1.png"
            alt="Waivpay"
            style={{maxHeight: '34px'}}
          />
        </div>
      </div>

      <div className="container-root container-maxWidthLg">
        <div className="banner">
          <div className="banner-wrapper">
            <div>
              <div className="banner-title">
                <h1>
                  Pay safer and faster using Waivpay with Apple Pay.
                </h1>
              </div>
              <div className="banner-icons">
                <div style={{paddingRight: '21.6px'}}>
                  <img
                    src="https://i.ibb.co/zmpF55n/logo-Big-1.png"
                    alt="Waivpay"
                    style={{maxHeight: '21px'}}
                  />
                </div>
                <hr className="divider vertical" />
                <div style={{paddingLeft: '21.6px'}}>
                  <img src="https://i.ibb.co/cXGMRXZ/Apple-Pay.png" alt="Apple Pay" />
                </div>
              </div>
            </div>
            <div className="banner-device">
              <div className="device">
                <img
                  src="https://i.ibb.co/VJHBh48/apple-iphone.png"
                  alt="iPhone"
                  width="209"
                  height="402"
                />
                <div className="device-card-wrapper">
                  <div
                    className="device-card-image"
                    style={{width: '160px', height: '102px', backgroundImage: "url('https://i.ibb.co/Z8JLV64/Waivpay-pan.png')"}}
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-setup">
        <div className="container-root container-maxWidthLg">
          <h1 className="card-setup-title">
            How to set your card in the Waivpay app and add it to Apple
            Wallet.
          </h1>
          <div className="card-setup-steps">
            <div className="card-setup-steps-item">
              <div className="hidden-md">
                <div className="card-setup-steps-item-wrapper">
                  <div className="card-setup-steps-item-line transparent"></div>
                  <div className="card-setup-steps-item-circle">1</div>
                  <div className="card-setup-steps-item-line"></div>
                </div>
              </div>
              <div className="card-setup-steps-item-text-wrapper">
                <div className="flex">
                  <div className="hidden-xs">
                    <div className="card-setup-steps-item-circle">1</div>
                  </div>
                  <p className="card-setup-steps-item-text">
                    Click on the link in the SMS
                  </p>
                </div>
              </div>
            </div>
            <div className="card-setup-steps-item">
              <div className="hidden-md">
                <div className="card-setup-steps-item-wrapper">
                  <div className="card-setup-steps-item-line"></div>
                  <div className="card-setup-steps-item-circle">2</div>
                  <div className="card-setup-steps-item-line"></div>
                </div>
              </div>
              <div className="card-setup-steps-item-text-wrapper">
                <div className="flex">
                  <div className="hidden-xs">
                    <div className="card-setup-steps-item-circle">2</div>
                  </div>
                  <p className="card-setup-steps-item-text">
                    Follow the prompts to add your Waivpay Gift Cards to
                    the app
                  </p>
                </div>
              </div>
            </div>
            <div className="card-setup-steps-item">
              <div className="hidden-md">
                <div className="card-setup-steps-item-wrapper">
                  <div className="card-setup-steps-item-line"></div>
                  <div className="card-setup-steps-item-circle">3</div>
                  <div className="card-setup-steps-item-line"></div>
                </div>
              </div>
              <div className="card-setup-steps-item-text-wrapper">
                <div className="flex">
                  <div className="hidden-xs">
                    <div className="card-setup-steps-item-circle">3</div>
                  </div>
                  <p className="card-setup-steps-item-text">
                    Tap the 'Add to Apple Wallet button' to add your card to
                    Apple Wallet.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-setup-steps-item">
              <div className="hidden-md">
                <div className="card-setup-steps-item-wrapper">
                  <div className="card-setup-steps-item-line"></div>
                  <div className="card-setup-steps-item-circle">4</div>
                  <div className="card-setup-steps-item-line"></div>
                </div>
              </div>
              <div className="card-setup-steps-item-text-wrapper">
                <div className="flex">
                  <div className="hidden-xs">
                    <div className="card-setup-steps-item-circle">4</div>
                  </div>
                  <p className="card-setup-steps-item-text">
                    We may verify your identity via SMS/email as part of the
                    setup
                  </p>
                </div>
              </div>
            </div>
            <div className="card-setup-steps-item">
              <div className="hidden-md">
                <div className="card-setup-steps-item-wrapper">
                  <div className="card-setup-steps-item-line"></div>
                  <div className="card-setup-steps-item-circle">5</div>
                  <div className="card-setup-steps-item-line transparent"></div>
                </div>
              </div>
              <div className="card-setup-steps-item-text-wrapper">
                <div className="flex">
                  <div className="hidden-xs">
                    <div className="card-setup-steps-item-circle">5</div>
                  </div>
                  <p className="card-setup-steps-item-text">
                    If you have an Apple Watch, you will be prompted to set this
                    up at the end
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hts">
        <div className="container-root container-maxWidthLg">
          <h1 className="hts-title">How to use Apple Pay</h1>
          <p className="hts-subtitle">
            Apple Pay is the safer, faster way to pay in stores with your iPhone
            and Apple Watch. Quickly check out with the devices you carry every
            day.
          </p>
          <div className="hts-devices">
            <div className="hts-devices-item">
              <div className="grid-root grid-container grid-spacing-xs-4">
                <div className="grid-root grid-item grid-grid-xs-12 grid-grid-sm-6">
                  <div className="left">
                    <div className="device">
                      <img
                        src="https://i.ibb.co/VJHBh48/apple-iphone.png"
                        alt="iPhone"
                        width="99"
                        height="189"
                      />
                      <div className="device-card-wrapper">
                        <div
                          className="device-card-image"
                          style={{width: '78px', height: '48px', backgroundImage: "url('https://i.ibb.co/Z8JLV64/Waivpay-pan.png')"}}
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-root grid-item grid-grid-xs-12 grid-grid-sm-6">
                  <div className="right">
                    <h2>To pay with Face ID on iPhone</h2>
                    <p>
                      Double-click the side button, glance at your screen, then
                      hold the iPhone near the reader.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hts-devices-item">
              <div className="grid-root grid-container grid-spacing-xs-4">
                <div className="grid-root grid-item grid-grid-xs-12 grid-grid-sm-6">
                  <div className="left">
                    <div className="watch">
                      <img
                        src="https://i.ibb.co/hBwyNs6/apple-watch.png"
                        height="158"
                        width="92"
                        alt="Apple Watch"
                      />
                      <div className="watch-card-wrapper">
                        <div
                          className="watch-card-image"
                          style={{width: '69px', height: '43px', backgroundImage: "url('https://i.ibb.co/Z8JLV64/Waivpay-pan.png')"}}
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-root grid-item grid-grid-xs-12 grid-grid-sm-6">
                  <div className="right">
                    <h2>To pay with Apple Watch</h2>
                    <p>
                      Double-click the side button and hold your Apple Watch up
                      to the reader.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hts-texts">
            <p className="MuiBox-root MuiBox-root-234">
              Apple Pay is also the safer and faster way to pay online and in
              apps because you no longer have to create an account or fill out
              lengthy forms. See here for help to Set Up Apple Pay on your
              device.
            </p>
            <p className="MuiBox-root MuiBox-root-236">
              You can use Apple Pay wherever you see one of these symbols.
            </p>
          </div>
          <div className="hts-icons">
            <div className="left">
              <img
                src="https://i.ibb.co/5crmrpy/mark-nfc-pay.png"
                alt="nfc-pay"
                width="121.5"
                height="74.77"
              />
            </div>
            <div className="right">
              <img
                src="https://i.ibb.co/T1Fv4DG/mark-apple-pay.png"
                alt="apple-pay"
                width="121.5"
                height="74.77"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-root container-maxWidthLg">
        <div className="faq">
          <div className="faq-vids-container">
            <div className="grid-root grid-container grid-spacing-xs-2">
              <div className="grid-root grid-item grid-grid-xs-12 grid-grid-sm-6">
                <h3>How to use Face ID with Apple Pay</h3>
                <div className="faq-vids-container-video">
                  <iframe
                    title="How to use Face ID with Apple Pay"
                    width="100%"
                    src="https://youtube.com/embed/4X4Vd130sbE"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    frameborder="0"
                    style={{minHeight: '259px'}}
                  ></iframe>
                </div>
              </div>
              <div className="grid-root grid-item grid-grid-xs-12 grid-grid-sm-6">
                <h3>How to use Touch ID with Apple Pay</h3>
                <div className="faq-vids-container-video">
                  <iframe
                    title="How to use Touch ID with Apple Pay"
                    width="100%"
                    src="https://www.youtube.com/embed/7QszoO0bRRk"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    frameborder="0"
                    style={{minHeight: '259px'}}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <h1>Apple Pay FAQs</h1>
          <div className="faq-item">
            <h3>How to use Apple Pay</h3>
            <p>
              You can use Apple Pay to make payments at any merchant where
              contactless payments are accepted. Simply look for the contactless
              symbol or Apple Pay mark. You may also use Apple Pay in-app and on
              the web with Safari when you see the “Buy with Apple Pay” button
              or Apple Pay as a payment option.
            </p>
          </div>
          <div className="faq-item">
            <h3>What is my Device Account Number, and where can I find it?</h3>
            <p>
              All cards stored in Apple Pay are allocated a Device Account
              Number. This unique number ensures your payment information isn’t
              stored on your device or shared with the merchant when making a
              payment. You can find your Device Account Number by opening the
              Wallet app, selecting your card and tapping the (i) symbol in the
              bottom righter corner.
            </p>
          </div>
          <div className="faq-item">
            <h3>Which cards are eligible for Apple Pay?</h3>
            <p>Apple Pay can be used with all Waivpay Gift Cards.</p>
          </div>
          <div className="faq-item">
            <h3>Where can I find out more information about Apple Pay?</h3>
            <p>
              To find out more information on how to make secure payments and
              set up Apple Pay with your Card, please visit the Apple Pay
              webpage
            </p>
          </div>
          <div className="faq-item">
            <h3>What Apple devices are compatible with Apple Pay?</h3>
            <p>
              Apple Pay works with iPhone 6 and later in stores, apps, and
              websites in Safari; with Apple Watch in stores and apps (requires
              iPhone 6 or later); For a list of compatible Apple Pay devices,
              see here.
            </p>
          </div>
        </div>
      </div>

      <div className="container-root container-maxWidthLg">
        <div className="footer">
          <div className="footer-title">
            <img
              src="https://i.ibb.co/zmpF55n/logo-Big-1.png"
              alt="Waivpay"
              style={{maxWidth: '83.03px'}}
            />
          </div>
          <div className="footer-links">
              <a className="item" href="https://www.waivpay.com/terms" target="_blank" rel="noreferrer">
                <p className="MuiBox-root MuiBox-root-302">Terms</p>
              </a>
              <a className="item" href="https://www.waivpay.com/privacy" target="_blank" rel="noreferrer">
                <p className="MuiBox-root MuiBox-root-302">Privacy</p>
              </a>
              <a className="item" href="https://www.waivpay.com/contact" target="_blank" rel="noreferrer">
                <p className="MuiBox-root MuiBox-root-302">Contact</p>
              </a>
          </div>
          <div className="footer-socials">
              <a className="item no-margin" href="https://www.facebook.com/WAIVPAY" target="_blank" rel="noreferrer">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 1.71429V14.2857C16 15.2321 15.2321 16 14.2857 16H11.2393V9.67143H13.4036L13.7143 7.25714H11.2393V5.71429C11.2393 5.01429 11.4321 4.53929 12.4357 4.53929H13.7143V2.38214C13.4929 2.35357 12.7357 2.28571 11.85 2.28571C10.0071 2.28571 8.74286 3.41071 8.74286 5.47857V7.26071H6.57143V9.675H8.74643V16H1.71429C0.767857 16 0 15.2321 0 14.2857V1.71429C0 0.767857 0.767857 0 1.71429 0H14.2857C15.2321 0 16 0.767857 16 1.71429Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a className="item" href="https://www.linkedin.com/company/waivpay" target="_blank" rel="noreferrer">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.8571 0H1.13929C0.510714 0 0 0.517857 0 1.15357V14.8464C0 15.4821 0.510714 16 1.13929 16H14.8571C15.4857 16 16 15.4821 16 14.8464V1.15357C16 0.517857 15.4857 0 14.8571 0ZM4.83571 13.7143H2.46429V6.07857H4.83929V13.7143H4.83571ZM3.65 5.03571C2.88929 5.03571 2.275 4.41786 2.275 3.66071C2.275 2.90357 2.88929 2.28571 3.65 2.28571C4.40714 2.28571 5.025 2.90357 5.025 3.66071C5.025 4.42143 4.41071 5.03571 3.65 5.03571ZM13.725 13.7143H11.3536V10C11.3536 9.11429 11.3357 7.975 10.1214 7.975C8.88571 7.975 8.69643 8.93929 8.69643 9.93571V13.7143H6.325V6.07857H8.6V7.12143H8.63214C8.95 6.52143 9.725 5.88929 10.8786 5.88929C13.2786 5.88929 13.725 7.47143 13.725 9.52857V13.7143Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a className="item" href="https://twitter.com/waivpay" target="_blank" rel="noreferrer">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2857 0H1.71429C0.767857 0 0 0.767857 0 1.71429V14.2857C0 15.2321 0.767857 16 1.71429 16H14.2857C15.2321 16 16 15.2321 16 14.2857V1.71429C16 0.767857 15.2321 0 14.2857 0ZM12.5393 5.67143C12.5464 5.77143 12.5464 5.875 12.5464 5.975C12.5464 9.07143 10.1893 12.6393 5.88214 12.6393C4.55357 12.6393 3.32143 12.2536 2.28571 11.5893C2.475 11.6107 2.65714 11.6179 2.85 11.6179C3.94643 11.6179 4.95357 11.2464 5.75714 10.6179C4.72857 10.5964 3.86429 9.92143 3.56786 8.99286C3.92857 9.04643 4.25357 9.04643 4.625 8.95C3.55357 8.73214 2.75 7.78929 2.75 6.65V6.62143C3.06071 6.79643 3.425 6.90357 3.80714 6.91786C3.48587 6.70415 3.22249 6.41424 3.04053 6.07398C2.85856 5.73373 2.76365 5.35372 2.76429 4.96786C2.76429 4.53214 2.87857 4.13214 3.08214 3.78571C4.23571 5.20714 5.96786 6.13572 7.91071 6.23571C7.57857 4.64643 8.76786 3.35714 10.1964 3.35714C10.8714 3.35714 11.4786 3.63929 11.9071 4.09643C12.4357 3.99643 12.9429 3.8 13.3929 3.53214C13.2179 4.075 12.85 4.53214 12.3643 4.82143C12.8357 4.77143 13.2929 4.63929 13.7143 4.45714C13.3964 4.925 12.9964 5.33929 12.5393 5.67143Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a className="item" href="https://www.instagram.com/waivpay" target="_blank" rel="noreferrer">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00179 3.8978C5.73162 3.8978 3.9005 5.72933 3.9005 8C3.9005 10.2707 5.73162 12.1022 8.00179 12.1022C10.2719 12.1022 12.1031 10.2707 12.1031 8C12.1031 5.72933 10.2719 3.8978 8.00179 3.8978ZM8.00179 10.667C6.53475 10.667 5.33542 9.47094 5.33542 8C5.33542 6.52906 6.53118 5.33304 8.00179 5.33304C9.47239 5.33304 10.6682 6.52906 10.6682 8C10.6682 9.47094 9.46882 10.667 8.00179 10.667ZM13.2274 3.73C13.2274 4.26197 12.7991 4.68682 12.2708 4.68682C11.739 4.68682 11.3142 4.2584 11.3142 3.73C11.3142 3.20161 11.7426 2.77318 12.2708 2.77318C12.7991 2.77318 13.2274 3.20161 13.2274 3.73ZM15.9438 4.7011C15.8831 3.41939 15.5904 2.28406 14.6516 1.34866C13.7165 0.413254 12.5814 0.120495 11.2999 0.0562312C9.97925 -0.0187437 6.02075 -0.0187437 4.70006 0.0562312C3.4222 0.116925 2.28712 0.409684 1.34835 1.34509C0.409593 2.28049 0.120468 3.41582 0.0562186 4.69753C-0.0187395 6.01852 -0.0187395 9.97791 0.0562186 11.2989C0.116899 12.5806 0.409593 13.7159 1.34835 14.6513C2.28712 15.5867 3.41863 15.8795 4.70006 15.9438C6.02075 16.0187 9.97925 16.0187 11.2999 15.9438C12.5814 15.8831 13.7165 15.5903 14.6516 14.6513C15.5868 13.7159 15.8795 12.5806 15.9438 11.2989C16.0187 9.97791 16.0187 6.02209 15.9438 4.7011ZM14.2376 12.7163C13.9592 13.416 13.4202 13.9551 12.717 14.2372C11.664 14.6549 9.16542 14.5585 8.00179 14.5585C6.83815 14.5585 4.33597 14.6513 3.28656 14.2372C2.58695 13.9587 2.04796 13.4196 1.76598 12.7163C1.34835 11.6631 1.44473 9.1639 1.44473 8C1.44473 6.8361 1.35192 4.33337 1.76598 3.28372C2.04439 2.58396 2.58338 2.04485 3.28656 1.7628C4.33954 1.34509 6.83815 1.44148 8.00179 1.44148C9.16542 1.44148 11.6676 1.34866 12.717 1.7628C13.4166 2.04128 13.9556 2.58039 14.2376 3.28372C14.6552 4.33694 14.5588 6.8361 14.5588 8C14.5588 9.1639 14.6552 11.6666 14.2376 12.7163Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
          </div>
          <p className="footer-copyrights">Waivpay Gift Cards all rights reserved</p>
          <p className="footer-copyrights">
            Apple Pay, Apple Watch, Face ID, iPhone are trademarks of Apple
            Inc., registered in the U.S. and other countries.
          </p>
        </div>
      </div>
    </div>
  </>
);

export default ApplepayPage;
